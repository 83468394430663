import React, {useEffect, useState} from 'react';
import {NavLink, Link} from "react-router-dom";

const HeaderMenu = (props) => {

  return(
      <>
      <div id="menu_overlay" onClick={props.handleHideMenu}/>
      <div id="menu">
        <nav>
          <div>
            <a href={"#0"} id={"menu_close"} onClick={props.handleHideMenu} />
            {
              React.Children.map(props.children, (child) => {
                return React.cloneElement(child, { onClick: props.handleHideMenu })
              })
            }
          </div>
        </nav>
      </div>
      </>
  )
}

const Header = () => {

  const [showMenu, setShowMenu] = useState(false)
  const handleShowMenu = () => setShowMenu(true)
  const handleHideMenu = () => setShowMenu(false)

  useEffect(()=> {

    if (showMenu) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

  },[showMenu])

  return(
    <header id="header" isopen={showMenu ? "1" : "0"}>
      <div id="header_wrapper">

        <div className="header_body" >
          {/*<NavLink className="logo" to="#0"/>
          <NavLink className="main_logo" to={""}/>*/}
          <Link className={"logo_wrapper"} to={"/"}/>
          <div id="burger" onClick={() => setShowMenu(!showMenu) }>
            <div/>
            <div/>
            <div/>
          </div>
        </div>

      </div>

      <HeaderMenu handleHideMenu={handleHideMenu}>
        <Link to="/">Accueil</Link>
        <Link to="/#comment-participer" smooth>Comment participer ?</Link>
        <Link to="/je-participe">Je participe</Link>
        <Link to="/jeu">Jouer pour ma team</Link>
        <Link to="/#chef-domicile">Un repas de chef à domicile</Link>
        <Link to="/#cours-cuisine">Les cours de cuisine en ligne</Link>
        <Link to="/recettes">Les recettes de crêpes Candia</Link>
        <Link to="/produits-eligibles">Les produits éligibles</Link>
        <Link to="/faq">FAQ</Link>

        <div>
          <Link to={"/#contactStrate1"} smooth>Contact</Link>
          <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du Jeu</a>
          <a href={"/file/Mentions%20Légales.pdf"} target={"_blank"}>Mentions Légales</a>
          <a href="/file/Politique%20de%20confidentialité.pdf" target={"_blank"}>Politique de Confidentialité</a>
          <a href="/file/Politique%20de%20cookies.pdf" target={"_blank"}>Politique de Cookies</a>
          <Link to="/informations-legales">Informations Légales</Link>
        </div>

      </HeaderMenu>

    </header>
  )
}

export default Header;

