const PageTitle = (props) => {

    return(
        <div className={`pageTitle strate ${props.className}`}>
            <h1 className={"line"}>{props.title}</h1>
            {props.children}
        </div>
    )
}

PageTitle.defaultProps = {
    title : ""
}

export default PageTitle