import React from 'react';

import Contact from "./contact";
import PageTitle from "./_title";
import {Link} from "react-router-dom";
import {TeamBloc} from "./home";

const FinOpe = () => {

	return(
		<>
			<PageTitle/>
			<div id="strate1" className={"strate"}>

				<h4>Merci à tous pour vos <br/>participations.</h4>

				<p>Nous espérons que vous vous êtes régalés et que vous avez <br className={"d-none d-lg-block"}/> découvert de nouvelles recettes avec les produits Candia !</p>
				<p>Retrouvez toute notre actualité sur <a href={"https://www.candia.fr"} target={"_blank"} className={"red fw-bold"}>www.candia.fr</a></p>
				<p><span>Venez défendre votre team préférée en récoltant <br className={"d-none d-lg-block"}/>le plus de points à notre mini jeu.</span></p>
				<br/>
				<div className={"text-center"}>
					<Link to={"/jeu"} className={"cta red small"}>Je joue</Link>
				</div>

				<div className={"visual_product"}/>
			</div>

			<TeamBloc/>
			<Contact/>
		</>
	);
}
export default FinOpe;
