import React from 'react';

//champs texte classique
const CustomUploadField = ({label, name, id=name, onchangeHandler = null, register, error, className="", warning = ""} ) =>{



  label = label.replace("*","<span>*</span>");

  return(
    <div className={"form_row_wrapper"} id={"form_row_wrapper_"+name}>
        <div className={"label_wrapper"}>
            <label htmlFor={id} dangerouslySetInnerHTML={{__html: label}}/>
        </div>
        <div className={"input_wrapper upload_wrapper"}>
            <input type="file" className="d-none"id={id+"_file"} name={name+"_file"} accept="image/png, image/jpeg, .pdf" onChange={onchangeHandler}/>
            <input className={className} type={"text"} id={id} name={name} placeholder={""} disabled={true} {...register}/>
            <label id={id+"_cta"} htmlFor={id+"_file"}>Télécharger</label>
        </div>
        <div className={"warning"} dangerouslySetInnerHTML={{__html : warning}}/>
        <div className="error">{error}</div>
    </div>
  );
}

export default CustomUploadField;
