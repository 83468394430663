import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Modal} from "react-bootstrap";
import Contact from "./contact";

const TeamBloc = () => {
    return(
        <div id={"teamStrate"} className={"strate"}>
            <h2>Decouvrez<br/>nos recettes</h2>
            <Link id="cta_teamsucree" to={"/recettes#team_sucree"} className={"cta red small"} smooth>En savoir +</Link>
            <Link id="cta_teamsalee" to={"/recettes#team_salee"} className={"cta red small"} smooth>En savoir +</Link>
        </div>
    )
}

const Home = () => {

    const [showStartModal, setShowStartModal] = useState(false)

    useEffect(() => {
        const dismissPopup = localStorage.getItem("candia_popup") ?? false

        if(!dismissPopup)
            setShowStartModal(true)
    }, [])

    const handleCloseModal = () => {
        localStorage.setItem("candia_popup",true)
        setShowStartModal(false)
    }

    const createCarouselHowto = () => {

        const content = [
            [
                "<span>Achetez simultanément deux produits éligibles</span> <br class='d-none d-lg-block'/>entre le 19/01/2024 et le 31/03/2024 inclus et conservez votre preuve d’achat pour participer.",
                "Les produits éligibles : Beurre Nature de Breton, GrandLait Frais et Lait Frais Marguerite. <a href='/produits-eligibles' class='fs-italic red underline' target='_blank'>Voir listing complet</a>."
            ],
            ["<span>Rendez-vous <a href='/je-participe' class='underline red' target='_blank'>ici</a> jusqu’au 10/04/2024 à 23h59 (heure de connexion en France Métropolitaine faisant foi)</span> pour compléter le formulaire de participation en renseignant vos coordonnées et en téléversant votre preuve d’achat."],
            [
                "<span>Si votre participation est valide, vous recevrez sous 2 semaines maximum un e-mail</span> avec votre code cadeau Académie du Goût et toutes les instructions pour bénéficier de votre abonnement de 3 mois de cours de cuisine en ligne.",
                "Vous serez aussi automatiquement inscrit au tirage au sort pour tenter de gagner un repas de chef à domicile."
            ],
            ["<span>Connectez-vous, au plus tard le 30/06/2024, sur le site <a class='red' href='https://www.academiedugout.fr/inscription' target='_blank'>www.academiedugout.fr</a></span>, créez votre compte et renseignez votre code cadeau pour profiter de votre abonnement de 3 mois de cours de cuisine en ligne offert. "],
            [
                "<span>Le tirage au sort</span> pour tenter de gagner un repas de chef à domicile sera effectué le 19/04/2024 et les deux gagnants seront contactés le 26/04/2024 au plus tard.",
                "Régalez-vous avec Candia !"
            ]
        ]

        let items = [];
        for(let i=0; i<5; i++){

            items.push(
                <Carousel.Item key={"HowToItem" + i}>
                    <div className={`carousel_howto_item`} id={"carousel_howto_item" + (i + 1)}>
                        <div className={"title"}>Etape {i + 1}</div>
                        <div className={"content"}>
                            {content[i].map((item) => {
                                return(<p dangerouslySetInnerHTML={{__html : item}}/>)
                            })}
                        </div>
                    </div>
                </Carousel.Item>
            )

        }

        let items_desktop = [];

        for(let i=0; i<5; i++){

            items_desktop.push(
                <div className={`home_howto_item`} id={"home_howto_item" + (i + 1)}>
                    <div className={"title"}>Étape {i + 1}</div>
                    <div className={"content"}>
                        {content[i].map((item) => {
                            return(<p dangerouslySetInnerHTML={{__html : item}}/>)
                        })}
                    </div>
                </div>
            )

        }

        return (
            <>
                <div id={"howto_carousel_wrapper"} className={"d-lg-none"}>
                    <Carousel controls={false} interval={null} indicators={true}>
                        {items}
                    </Carousel>
                </div>
                <div id={"home_howto_wrapper"} className={"d-lg-flex d-none"}>
                    {items_desktop}
                </div>
            </>
        );
    }

    return (
        <>
            <Modal show={showStartModal} onHide={handleCloseModal} id={"modalHome"} centered={true} backdrop={"static"}>
                <Modal.Body>
                    <Link id={"cta_start_jeu"} to={"/jeu"} onClick={handleCloseModal}>Découvrir le jeu</Link>
                    <Link id={"cta_start_decouvrir"} to={"/"} onClick={handleCloseModal}>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>

            <div id={"homeStrate1"} className={"strate first"}>

                <p>
                    Du 19/01/2024 au 31/03/2024 <br/>
                    Pour l'achat simultané de 2 produits éligibles*
                </p>

                <Link className={"cta blue small"} to={"/je-participe"}>Je participe</Link>

            </div>

            <div id={"homeStrate2"} className={"strate"}>
                <div className={"anchor"} id={"comment-participer"}/>
                <h2>Comment <br/>participer ?</h2>
                {createCarouselHowto()}
            </div>

            <div id={"homeStrate3"} className={"strate"}>
                <div className={"anchor"} id={"chef-domicile"}/>
                <div className={"content_wrapper"}>
                    <h2>
                        Un repas de chef<br/>
                        <span className={"accent"}>a</span> domicile
                    </h2>
                    <p>Envie de vivre une expérience gastronomique <br/>inoubliable à la maison ? C’est possible !</p>
                    <p>Notre conciergerie vous organise un repas de chef à domicile sur-mesure d’une valeur pour aller jusqu’à 400€. Mettez les pieds sous la table et régalez-vous, nous nous occupons de tout !</p>
                    <p>Deux repas de chef à domicile seront mis en jeu et attribués lors d’un tirage au sort réalisé <br className={"d-lg-block d-none"}/>le 19/04/2024. Ces repas doivent être organisés avant le 30/06/2024 et effectués le 31/12/2024 au plus tard.</p>
                </div>

            </div>

            <div id={"homeStrate4"} className={"strate"}>
                <div className={"anchor"} id={"cours-cuisine"}/>
                <div className={"content_wrapper"}>
                    <h2>
                        Les cours<br/>de cuisine en ligne
                    </h2>
                    <p>Explorez un monde de saveurs grâce à un abonnement de 3 mois de cours de cuisine en ligne offert à l’Académie du Goût  !</p>
                    <p>Éveillez vos papilles et devenez un chef accompli en apprenant les secrets des experts culinaires à la maison. N'attendez plus, relevez le défi et impressionnez vos proches lors de vos prochains dîners !</p>
                    <p>Code cadeau valable jusqu’au 30/06/2024 sur le site <a href={"https://www.academiedugout.fr/inscription"} target={"_blank"} className={"red"}>www.academiedugout.fr</a>.</p>
                    <a href={"https://www.academiedugout.fr"} target={"_blank"}/>
                </div>
            </div>

            <TeamBloc/>

            <Contact/>
        </>
    );
};


export default Home;
export {TeamBloc};