import React from 'react';

const CustomComboBox = ({label, name, id=name, options = [], placeholder="", register, error, className="", onChange= null} ) =>{

  label = label.replace("*","<span>*</span>");

  return(
    <div className={"form_row_wrapper"} id={"form_row_wrapper_"+id}>

      <div className={"label_wrapper"}>
        <label dangerouslySetInnerHTML={{__html: label}}/>
      </div>

      <div className={"input_wrapper"}>
        <select name={name} id={id} defaultValue={""} className={className} {...register} /*onChange={onChange}*/>
            <optgroup>
                {placeholder !== "" && <option disabled={true} value="">{placeholder}</option>}
                {options.map( (option, key) => <option key={key} value={option[1]}>{option[0]}</option>)}
            </optgroup>
        </select>
      </div>

        <div className="error" dangerouslySetInnerHTML={{__html : error}}/>

    </div>
  );
}

export default CustomComboBox;