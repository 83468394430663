import React from 'react';

import PageTitle from "./_title";
import {Carousel} from "react-bootstrap";

const ProduitsEligibles = () => {

    const createCarouselProduct = (id) => {

        const offset = (id-1) * 2

        const content = [
            "Nature de Breton<br/>Beurre traditionnel<br/>Doux Bretagne<span>(250g)</span>",
            "Nature de Breton<br/>Beurre traditionnel<br/>Demi-sel Bretagne<span>(250g)</span>",
            "GrandLait frais<br class='d-lg-none'/> de Montagne <br class='d-lg-block d-none'/>Demi-écrémé<span>(2L et 1L)</span>",
            "GrandLait frais<br class='d-lg-none'/> de Montagne <br class='d-lg-block d-none'/>Entier<span>(1L et 2L)</span>",
            "Marguerite Lait frais <br/>Microfiltré Demi-écrémé<span>(1L)</span>",
            "Marguerite Lait frais <br/>Microfiltré Entier <span>(1L)</span>"
        ]

        let items = [];
        let items_desktop = [];
        for(let i=offset; i<(2+offset); i++){
            items.push(
                <Carousel.Item key={"ProductItem" + i}>
                    <div className={`carousel_product_item`} id={"carousel_product_item" + (i + 1)}>
                        <div className={"description"} dangerouslySetInnerHTML={{__html : content[i]}}/>
                    </div>
                </Carousel.Item>
            )
            items_desktop.push(
                <div className={`product_item`} id={"product_item" + (i + 1)}>
                    <div className={"description"} dangerouslySetInnerHTML={{__html : content[i]}}/>
                </div>
            )
        }

        return (
            <>
                <div id={`carousel_product${id}_wrapper`} className={"d-lg-none"}>
                    <Carousel controls={false} interval={null} indicators={true}>
                        {items}
                    </Carousel>
                </div>

                <div id={`product${id}_wrapper`} className={"product_wrapper d-lg-flex d-none"}>
                    {items_desktop}
                </div>
            </>
        );
    }

    return(
        <>
            <PageTitle title={"Produits éligibles"}/>
            <div id={"produitStrate1"} className={"strate"}>
                {createCarouselProduct(1)}
                <div className={"detail_bloc"}>
                    <div className={"title"}>Ses atouts</div>
                    <div className={"content"}>
                        Découvrez la touche de gourmandise bretonne dont <br className={"d-lg-none"}/>
                        vous aviez besoin ! <br className={"d-none d-lg-block"}/>Le bon beurre traditionnel <br className={"d-lg-none"}/>
                        Nature de Breton est produit exclusivement avec <br/>
                        des crèmes bretonnes dans notre beurrerie de <br className={"d-lg-none"}/>
                        Quimper. Son emballage <br className={"d-none d-lg-block"}/>inédit est composé à 96% <br className={"d-lg-none"}/>
                        de papier recyclable.
                    </div>
                </div>
            </div>
            <div id={"produitStrate2"} className={"strate"}>
                {createCarouselProduct(2)}
                <div className={"detail_bloc"}>
                    <div className={"title"}>Ses atouts</div>
                    <div className={"content"}>
                        Découvrez le goût unique du lait frais de montagne <br className={"d-lg-none"}/>
                        GrandLait, collecté dans <br className={"d-none d-lg-block"}/>des exploitations à taille <br className={"d-lg-none"}/>
                        humaine. Il est simplement pasteurisé afin de <br/>
                        préserver la saveur du bon lait frais de montagne !
                    </div>
                </div>
            </div>
            <div id={"produitStrate3"} className={"strate"}>
                {createCarouselProduct(3)}
                <div className={"detail_bloc"}>
                    <div className={"title"}>Ses atouts</div>
                    <div className={"content"}>
                        Marguerite c’est l’authentique goût du lait comme <br className={"d-lg-none"}/>
                        si vous étiez à la ferme. <br className={"d-none d-lg-block"}/>Idéal pour cuisiner, il <br className={"d-lg-none"}/>
                        garantit une saveur et une onctuosité proche de <br className={"d-lg-none"}/>
                        celles <br className={"d-none d-lg-block"}/>du lait cru.
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProduitsEligibles;
