import React, {useCallback, useEffect, useRef, createRef, useImperativeHandle, useState} from "react";
import {useSetState} from "react-use";
import {createObject} from "./utils";

import {Modal} from "react-bootstrap";

import {
    SPEED_STEP,
    SPEED_BASE,
    SPAWN_INTERVAL_BASE,
    SPAWN_INTERVAL_MIN,
    SPAWN_INTERVAL_STEP,
    SPEED_MULTIPLICATOR_STEP,
    TRAP_SKIN,
    COIN_SKIN
} from "./constant";

import {Link} from "react-router-dom";

const FallingObject = React.forwardRef((props, ref) => {
    const {x, y, type, index, skin} = props;

    const objectStyle = {
        left: `${x}px`,
        top: `${y}px`
    };

    const hitboxRef = createRef()
    const objectRef = createRef()

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get object() {
            return objectRef.current;
        }
    }));

    return(
        <div className={"falling_object "+type+" "+skin} id={"fo_"+index} style={objectStyle} ref={objectRef} data-value={skin}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const Player = React.forwardRef((props, ref) => {

    const {x} = props;
    const hitboxRef = createRef()
    const playerRef = createRef()

    const playerStyle = {
        left: `${x}%`
    };

    useImperativeHandle(ref, () => ({
        get hitbox() {
            return hitboxRef.current;
        },
        get player() {
            return playerRef.current;
        }
    }));

    useEffect(() => {

        if(props.animation){
            playerRef.current.classList.add("animate")

            playerRef.current.onanimationend = function() {
                props.resetAnimation()
                this.onanimationend = null
                this.classList = ""
            }
        }

    },[props.animation])

    return(
        <div id={"player"} style={playerStyle} ref={playerRef} className={`${props.animation}`} direction={props.direction}>
            <div className={"hitbox"} ref={hitboxRef}/>
        </div>
    )
})

const GameUi = (props) => {

    const {points, lives, team} = props;

    return(
        <div id={"game_ui"}>
            <div id={"team"} className={team}/>
            <div className={"instruction"}>
                <div className={"mobile"}>
                    Déplacez la poêle à droite<br/>
                    ou à gauche à l’aide de vos doigts
                </div>

                <div className={"desktop"}>
                    Déplacez la poêle<br/>
                    à l’aide de votre clavier
                </div>

            </div>
            <div className={"score"}>{points} point{points>1 && "s"}</div>
            <div className={"lives"} data-lives={lives}>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

const CoinCatcher = () => {

    //global state
    const [globalState, setGlobalState] = useSetState({
        bestScoreSalee : 0,
        bestScoreSucree : 0,
        totalScoreSalee : 0,
        totalScoreSucree : 0,
    })

    //state
    const initialState = {
        objects: [],
        points : 0,
        lives : 3,
        isRunning : false,
        playerPosition : 50,
        playerAnimation : "",
        speed : SPEED_BASE,
        spawnTimer : SPAWN_INTERVAL_BASE,
        keyPress : null,
        playerRotation : "right",
        team : "salee",
        skin : {
            good : [],
            trap : []
        }
    }
    const [gameState, setGameState] = useSetState(initialState)

    //falling object type
    const neutralObject = ["butter", "flour", "milk", "egg"]
    const saltObject = ["carrot", "spinach", "leak", "fish", "potato", "tomato"]
    const sugarObject = ["choco", "jam", "strawberry", "honey", "sugar"]

    //Modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showEndModal, setShowEndModal] = useState(false);

    //ref
    const requestRef = useRef();
    const gameRef = useRef();
    const playerRef = useRef();

    const toggleFullScreen = (state) => {

        if ("ontouchstart" in document.documentElement){
            let elem = document.getElementById("fullscreenHandler")

            if(state){
                if (document.exitFullscreen) {
                    elem.requestFullscreen()
                }

            }else{

                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => console.error(err));
                }

            }
        }

    }

    //requestFrame
    const advanceStep = useCallback(() => {

        setGameState((oldState) => {

            //move object and detect hitbox
            const newObject = [];
            for(let object of oldState.objects){

                const newY = object.y + object.speed;

                //object only exist in state and not in DOM
                //we ignore it
                if(object.ref.current === null) {
                    newObject.push({...object,y: newY});
                    continue
                }

                //objet out of screen -> remove
                if (newY > ( gameRef.current.offsetHeight + object.ref.current.offsetHeight))
                    continue

                //check falling object hitbox
                const objBoundary = object.ref.current.hitbox.getBoundingClientRect();
                const playerBoundary = playerRef.current.hitbox.getBoundingClientRect();

                const hOverlap = (objBoundary.left <= playerBoundary.right && objBoundary.right >= playerBoundary.left)
                const vOverlap = (objBoundary.bottom >= playerBoundary.top && objBoundary.top <= playerBoundary.bottom)

                if( vOverlap && hOverlap ){

                    //get point or lose lives
                    if(object.type === "good"){
                        setPlayerPoint(oldState.points,3)
                    }else{
                        //animate bad
                        handlePlayerLoseLive(oldState.lives)
                    }

                }else{
                    //move object
                    newObject.push({...object,y: newY,});
                }
            }

            //move player
            let playerPosition = oldState.playerPosition

            if(oldState.keyPress === "left" && playerPosition > 10){
                playerPosition -= 1
            }
            else if(oldState.keyPress === "right" && playerPosition < 90){
                playerPosition += 1
            }

            return {
                objects : newObject,
                playerPosition : playerPosition
            }

        });

        requestRef.current = requestAnimationFrame(advanceStep);

    }, [gameState.objects]);

    //create random falling object
    const spawnFallingObject = () => {
        //choose between coin and trap
        //1/3 -> trap
        //2/3 -> coin
        const rand = Math.floor(Math.random() * 30)
        let type = rand >= 20 ? "trap":"good"

        let skin
        if(type === "good"){
            const randSkin = Math.floor(Math.random() * gameState.skin.good.length)
            skin = gameState.skin.good[randSkin]
        }else{
            const randSkin = Math.floor(Math.random() * gameState.skin.trap.length)
            skin = gameState.skin.trap[randSkin]
        }

        const objRef = createRef()

        setGameState((oldState) => {

            if(oldState.isRunning)
                setTimeout(spawnFallingObject,oldState.spawnTimer)

            return { objects : [...oldState.objects, createObject(type, oldState.speed, objRef, skin)]}
        })


    }

    //return calculate object position
    const getObjectXPosition = (obj) => {

        const Box = gameRef.current.offsetWidth
        //const objBox = obj.ref.current.offsetWidth

        return ( Box * (obj.x / 100) ) - (20 / 2)
    }

    //move player
    const handlePlayerKeyDown = (e) => {
        let key = null

        //save key in state
        switch (e.which) {
            case 37: key = "left"; break;
            case 39: key = "right"; break;
        }

        setGameState({
            keyPress : key,
            playerRotation : key
        })
    }
    const handlePlayerKeyUp = () => {
        setGameState({keyPress : null})
    }

    //lose a live
    const handlePlayerLoseLive = (currentLives) => {
        const newLives = currentLives-1

        setGameState((oldState) => {

            if(newLives <= 0){
                //setShowEndModal(true)
                getPlayerScore(oldState.points, oldState.team)
            }

            return({
                lives : newLives,
                isRunning : newLives > 0,
                playerAnimation : "tilt"
            })

        })

    }

    //getSpeed for current score
    const setPlayerPoint = (currentPoints, points) => {

        const newScore = currentPoints+points
        const speed_multiplicator = Math.floor(newScore/SPEED_MULTIPLICATOR_STEP)
        const speed = SPEED_BASE + (SPEED_STEP * speed_multiplicator)
        let spawnTime = SPAWN_INTERVAL_BASE - ( SPAWN_INTERVAL_STEP * speed_multiplicator )

        if(spawnTime < SPAWN_INTERVAL_MIN)
            spawnTime = SPAWN_INTERVAL_MIN

        setGameState({
            points : newScore,
            speed : speed,
            spawnTimer : spawnTime,
            playerAnimation : "point"
        })

    }

    //game loop
    useEffect(() => {

        const stop = () => {
            requestRef.current && cancelAnimationFrame(requestRef.current);

            toggleFullScreen(false)

            //window.mixpanelhandler.track("Game completed",{"Score" : gameState.points, "Game Name" : "The race for gold coins"})
        }

        if (gameState.isRunning) {
            //intervalRef.current = setInterval(spawnFallingObject, gameState.spawnTimer);
            setTimeout(spawnFallingObject, gameState.spawnTimer);
            requestRef.current = requestAnimationFrame(advanceStep);
        } else {
            stop();
        }

        return () => stop();
    }, [gameState.isRunning])

    //player move
    useEffect(() => {

        document.addEventListener('keydown', handlePlayerKeyDown);
        document.addEventListener('keyup', handlePlayerKeyUp);

        document.querySelectorAll("#mobile_game_control > div").forEach((item) => {
            item.addEventListener('touchstart', function(e){

                handlePlayerKeyDown({which : parseInt(this.dataset.key)})
            })

            item.addEventListener('touchend', function(e){
                console.log("release")
                handlePlayerKeyUp()
            })

        });

        return ()=> {
            document.removeEventListener('keydown', handlePlayerKeyDown);
            document.removeEventListener('keyup', handlePlayerKeyUp);
        }
    }, []);

    //start game
    const startGame = (team) => {
        let skin_good = []
        let skin_trap = []

        if(team === "salee"){
            skin_good = [...neutralObject, ...saltObject]
            skin_trap = [...sugarObject]

            //click event
            window.mixpanelhandler.track("Participation_Click on \"Sweet crepe team\" play button", {"Result count" : 1})

        }else{
            skin_good = [...neutralObject, ...sugarObject]
            skin_trap = [...saltObject]

            window.mixpanelhandler.track("Participation_Click on \"Savoury crepe team\" play button", {"Result count" : 1})
        }

        //setfullScreen
        toggleFullScreen(true)

        setShowStartModal(false)
        setGameState({
            team : team,
            skin : {
                good : skin_good,
                trap : skin_trap
            },
            isRunning: true
        })
    }

    //reset game
    const resetGame = () => {

        window.mixpanelhandler.track("Participation_Game Replay Submitted",{"Result count" : 1})

        setGameState(initialState)

        setShowEndModal(false);
        setShowStartModal(true);
    }

    //get Score
    const getPlayerScore = (score, team) => {

        let formData = new FormData();
        formData.append("action", "get_score");
        formData.append("team", team);
        formData.append("score", score);

        if(team === "salee")
            window.mixpanelhandler.track("Participation_\"Savoury crepe team\" completed",{"Result count" : 1})
        else
            window.mixpanelhandler.track("Participation_\"Sweet crepe team\" completed",{"Result count" : 1})

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {

                    setGlobalState({
                        bestScoreSalee : json.data.maxSalee,
                        bestScoreSucree : json.data.maxSucree,
                        totalScoreSalee : json.data.sumSalee,
                        totalScoreSucree : json.data.sumSucree,
                    })

                    setShowEndModal(true)


                    /*window.mixpanelhandler.track("Participation_Form Submitted",{
                        "Result count" : 1,
                        "Civility / Gender" : data.civilite,
                        "Date of Birth" : data.jour_naissance+"/"+data.mois_naissance+"/"+data.annee_naissance
                    })*/

                }

            });

    }

    /*useEffect(() => {
        setGameState({isRunning: true})
    },[])*/

    return(
        <div id={"fullscreenHandler"}>

            <Modal show={showStartModal} onHide={() => setShowStartModal(false)} id={"modalStartGame"} centered={true} backdrop={"static"}>
                <Modal.Body>
                   <a id={"cta_start_sucree"} href={"#0"} onClick={() => startGame("sucree")}>Choisir</a>
                   <a id={"cta_start_salee"} href={"#0"} onClick={() => startGame("salee")}>Choisir</a>
                   <a id={"cta_start_decouvrir"} href={"/#chef-domicile"}>Découvrir l'offre</a>
                </Modal.Body>
            </Modal>
            <Modal show={showEndModal} onHide={() => setShowEndModal(false)} id={"modalEndGame"} centered={true} backdrop={"static"} >
                <Modal.Body className={gameState.team}>
                    <div className={"playerScore"}>Votre score est de {gameState.points} pts!</div>
                    <div className={"bestScore"}>
                        le meilleur score est de&nbsp;
                        {gameState.team === "salee" && globalState.bestScoreSalee}
                        {gameState.team === "sucree" && globalState.bestScoreSucree}
                        &nbsp;pts
                    </div>
                    <div className={"score_salee"}>Score total <span>{globalState.totalScoreSalee} pts</span></div>
                    <div className={"score_sucree"}>Score total <span>{globalState.totalScoreSucree} pts</span></div>

                    <a href={"#0"} id={"cta_reset"} onClick={resetGame}>Rejouer</a>
                    <Link to={"/recettes"} id={"cta_recipe"} onClick={() => {
                        if(gameState.team === "salee")
                            window.mixpanelhandler.track("Participation_Ending page \"Savoury crepe team\" click on recipe button",{"Result count" : 1})
                        else
                            window.mixpanelhandler.track("Participation_Ending page \"Sweet crepe team\" click on recipe button",{"Result count" : 1})
                    }}>Découvrir</Link>
                    <Link to={"/"} id={"cta_discover"} onClick={() => {
                        if(gameState.team === "salee")
                            window.mixpanelhandler.track("Participation_Ending page \"Sweet crepe team\" click on offer button",{"Result count" : 1})
                        else
                            window.mixpanelhandler.track("Participation_Ending page \"Savoury crepe team\" click on offer button",{"Result count" : 1})
                    }}>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>

            <div className={"mobile_landscape_switcher"}/>
            <div id={"game_coin_wrapper"} className={"game_wrapper"} ref={gameRef}>
                <GameUi lives={gameState.lives} points={gameState.points} team={gameState.team}/>
                <div id={"mobile_game_control"}>
                    <div data-key={37} className={"left"}/>
                    <div data-key={39} className={"right"}/>
                </div>

                {gameState.objects.map((o, index) => {
                    return( <FallingObject x={getObjectXPosition(o)} y={o.y} index={index} type={o.type} ref={o.ref} skin={o.skin}/> )
                })}

                <Player x={gameState.playerPosition} animation={gameState.playerAnimation} resetAnimation={ () => setGameState({playerAnimation : ""}) } direction={gameState.playerRotation} ref={playerRef}/>
            </div>
        </div>
    )
}

export default CoinCatcher