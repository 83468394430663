import React, {useRef, useState} from 'react';

const Contact = () => {

  return (
    <>
      <div id="contactStrate1" className={"strate bottom"}>
        <h3>Vous avez des questions<br/> ou besoin de plus <br className={"d-lg-none"}/>d’informations ?</h3>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0974591770"}>09 74 59 17 70</a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel local, tarif en vigueur <br className={"d-lg-block d-none"}/>selon <br className={"d-lg-none"}/>
                opérateur. Ligne active du lundi au vendredi, de 9h30 <br className={"d-lg-block d-none"}/>à 17h30, <br className={"d-lg-none"}/>
                hors jours fériés)
              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>Par e-mail</div>
              <div className={"contact_link"}>
                <a href={"mailto:candia-chandeleur@tlcrewards.com?subject=Op%C3%A9ration%20%22Candia%20Chandeleur%22%20%2F%20Vos%20nom%20et%20pr%C3%A9nom"}>candia-chandeleur@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                (Objet : Opération "Candia Chandeleur" / Vos nom et prénom)
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Contact;
