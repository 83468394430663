export const createObject = (type, speed, ref, skin) => {

    let x = Math.floor(Math.random() * ( 90 - 10) + 10);
    return {x,y: 0, index : 1, type: type, ref : ref, speed : speed, skin : skin}
};

export const removeCoin = (coins, index) => {
    const newCoins = [...coins];
    newCoins.splice(index, 1);
    return newCoins;
};