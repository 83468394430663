import React, {useState, useRef, useEffect} from 'react';

import {Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import CustomFieldText from '../form/CustomFieldText';
import CustomComboBox from "../form/CustomComboBox";
import BirthDayField from '../form/BirthDayField';

import {TeamBloc} from './home';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";
import Contact from "./contact";
import PageTitle from "./_title";
import CustomUploadField from "../form/CustomUploadField";
import {Link} from "react-router-dom";
import {numberPattern_nospace} from "../form/form_pattern_helper.jsx";


const JeParticipeForm = (props) => {

    const [file, setFile] = useState("")

    const {register, handleSubmit, formState: { errors }, setValue, clearErrors, setError } = useForm({validateCriteriaMode: "all"})
    const recaptchaRef = useRef(null)
    const submitRef = useRef(null)

    function setFilename(event,fieldname,setFileHandle) {

        if (event.target.files[0] !== undefined && Helper.checkIsUploadable(event.target.files[0])) {
            setValue(fieldname, event.target.files[0].name);
            setFileHandle(event.target.files[0]);
            clearErrors(fieldname);
        } else if (!Helper.checkIsUploadable(event.target.files[0])) {
            setError(fieldname, "filesize", Helper.InvalidErrorMessages.filesize);
        }
    }

    //form validation
    const onSubmit = data => {

        //console.log(data)
        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);
        formData.append("ticket", file);
        formData.append("action", "participate");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    window.mixpanelhandler.track("Participation_Form Submitted",{
                        "Result count" : 1,
                        "Civility / Gender" : data.civilite,
                        "Date of Birth" : data.jour_naissance+"/"+data.mois_naissance+"/"+data.annee_naissance
                    })
                    props.setShowMerci(true);
                } else {

                    switch(json.message){
                        case  "#recaptcha" :
                            alert("Veuillez cocher la case \"Je ne suis pas un robot\" pour continuer.");
                            break;

                        case "#already_participate" :
                            alert("L'opération est limité à une participation par personne.");
                            break;

                        case "#forbidden_cp" :
                            alert("Cette opération est limitée aux résidents de France Métropolitaine et de Corse uniquement !");
                            break;

                        default :
                            alert(json.message);
                    }
                    //setFormError(json.error);


                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <div className={"participe_wrapper"}>
            <PageTitle title={"Complétez le formulaire"}/>
            <div id={"participeStrate1"} className={"strate milk"}>

                <h4>Complétez le formulaire<br/>ci-dessous pour</h4>
                <div className={"dotation"}/>

                <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

                    <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"Sélectionner"}
                                    options={[
                                        [
                                            "Madame",
                                            "Madame"
                                        ],
                                        [
                                            "Monsieur",
                                            "Monsieur"
                                        ],
                                        [
                                            "Ne souhaite pas se prononcer",
                                            "Ne souhaite pas se prononcer"
                                        ]
                                    ]}
                                    register={register("civilite",{
                                        required:  Helper.EmptyErrorMessages.civilite
                                    })}
                                    error={errors.civilite && errors.civilite.message}/>

                    <CustomFieldText name="prenom" label="Prénom*" register={register('prenom', {
                        required: Helper.EmptyErrorMessages.prenom,
                        pattern: { value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom },
                        minLength: { value: 2, message: Helper.InvalidErrorMessages.prenom }
                    })} error={errors.prenom && errors.prenom.message}/>
                    <CustomFieldText name="nom" label="Nom*" register={register('nom',{
                        required: Helper.EmptyErrorMessages.nom,
                        pattern: { value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom },
                        minLength: { value: 2, message: Helper.InvalidErrorMessages.nom }
                    })} error={errors.nom && errors.nom.message}/>

                    <CustomFieldText name="mail" label="Adresse e-mail*"
                                     register={
                                         register("mail",{
                                             required: true,
                                             pattern : Pattern.emailPattern,
                                             validate: {
                                                 domain: (value) => Helper.checkMailDomain(value)
                                                 /*confirm : checkMailConfirm*/
                                             }
                                         })}
                                     error={[
                                         errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                         errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                         errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                         errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                     ]}/>

                    <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                     register={
                                         register("mail_confirm",{
                                             required: true,
                                             pattern : Pattern.emailPattern,
                                             validate: {
                                                 domain : (value) => Helper.checkMailDomain(value),
                                                 confirm: Helper.checkMailConfirm
                                             }
                                         })}
                                     error={[
                                         errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                         errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                         errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                         errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                     ]}/>

                    <BirthDayField
                        label={"Date de naissance<span>*</span>"}
                        register={register}
                        errors={errors}/>

                    <CustomFieldText name="adresse" label="Adresse postale*" register={register('adresse', {
                        required: Helper.EmptyErrorMessages.adresse,
                        pattern : {
                            value  : Pattern.numberAndAlphaPattern,
                            message: Helper.InvalidErrorMessages.adresse
                        }
                    })} error={errors.adresse && errors.adresse.message}/>

                    <CustomFieldText name="cp" label="Code postal*" register={register('cp', {
                        required : {value: true, message: Helper.EmptyErrorMessages.cp},
                        pattern  : {
                            value  : Pattern.numberPattern_nospace,
                            message: Helper.InvalidErrorMessages.cp
                        },
                        minLength: {value: 5, message: Helper.InvalidErrorMessages.cp},
                        maxLength: {value: 5, message: Helper.InvalidErrorMessages.cp}
                    })} error={errors.cp && errors.cp.message}/>

                    <CustomFieldText name="ville" label="Ville*" register={register('ville', {
                        required : {value: true, message: Helper.EmptyErrorMessages.ville},
                        pattern  : {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.ville},
                        minLength: {value: 2, message: Helper.InvalidErrorMessages.ville}
                    })} error={errors.ville && errors.ville.message}/>


                    <CustomUploadField
                        label={"Preuve d’achat <br class='d-none d-lg-block'/>(ticket de caisse ou facture)<span>*</span>"}
                        warning={"*Votre fichier ne doit pas dépasser les 7MO et <br class='d-lg-none'/>doit être au format JPEG, JPG, PNG ou PDF."}
                        name={"ticket"}
                        onchangeHandler={(e) => setFilename(e,"ticket",setFile)}
                        register={register("ticket",{required : Helper.EmptyErrorMessages.ticket})}
                        error={errors.ticket && errors.ticket && errors.ticket.message}
                    />

                    <CustomComboBox name="produit1" id="produit1" label={"Nom du 1<sup>er</sup> produit acheté"} placeholder={"Sélectionner"}
                                    options={[
                                        [
                                            "Nature de Breton beurre traditionnel demi-sel Bretagne",
                                            "Nature de Breton beurre traditionnel demi-sel Bretagne"
                                        ],
                                        [
                                            "Nature de Breton beurre traditionnel doux Bretagne",
                                            "Nature de Breton beurre traditionnel doux Bretagne"
                                        ],
                                        [
                                            "GrandLait frais demi-écrémé",
                                            "GrandLait frais demi-écrémé"
                                        ],
                                        [
                                            "GrandLait frais entier",
                                            "GrandLait frais entier"
                                        ],
                                        [
                                            "Marguerite Lait frais micro-filtré demi-écrémé",
                                            "Marguerite Lait frais micro-filtré demi-écrémé"
                                        ],
                                        [
                                            "Marguerite Lait frais micro-filtré entier",
                                            "Marguerite Lait frais micro-filtré entier"
                                        ]
                                    ]}
                                    register={register("produit1")}/>

                    <CustomComboBox name="produit2" id="produit2" label={"Nom du 2<sup>ème</sup> produit acheté"} placeholder={"Sélectionner"}
                                    options={[
                                        [
                                            "Nature de Breton beurre traditionnel demi-sel Bretagne",
                                            "Nature de Breton beurre traditionnel demi-sel Bretagne"
                                        ],
                                        [
                                            "Nature de Breton beurre traditionnel doux Bretagne",
                                            "Nature de Breton beurre traditionnel doux Bretagne"
                                        ],
                                        [
                                            "GrandLait frais demi-écrémé",
                                            "GrandLait frais demi-écrémé"
                                        ],
                                        [
                                            "GrandLait frais entier",
                                            "GrandLait frais entier"
                                        ],
                                        [
                                            "Marguerite Lait frais micro-filtré demi-écrémé",
                                            "Marguerite Lait frais micro-filtré demi-écrémé"
                                        ],
                                        [
                                            "Marguerite Lait frais micro-filtré entier",
                                            "Marguerite Lait frais micro-filtré entier"
                                        ]
                                    ]}
                                    register={register("produit2")}/>

                    <div className={"optin_container"}>

                        <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_reglement" id="optin_reglement" {...register("optin_reglement",{required: Helper.EmptyErrorMessages.optin_reglement})}/>

                            <label htmlFor="optin_reglement">
                                Je reconnais avoir pris connaissance du <a href={"/file/Règlement.pdf"} target={"_blank"} className={"underline"}>Règlement du Jeu</a> de l’opération et en accepter les modalités.<span>*</span>
                            </label>
                            <div className="error text-center">{errors.optin_reglement && errors.optin_reglement.message}</div>
                        </div>

                        <div id={"optin_partenaire_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_partenaire" id="optin_partenaire" {...register("optin_partenaire",{required: Helper.EmptyErrorMessages.optin_consent})}/>

                            <label htmlFor="optin_partenaire">
                                En cochant cette case, j’accepte que mes données soient traitées par Candia dans le cadre de ce jeu concours.<span>*</span>
                            </label>

                            <div className="error text-center">{errors.optin_partenaire && errors.optin_partenaire.message}</div>
                        </div>
                        {/*
                        <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <input type="checkbox" name="optin_tlc" id="optin_tlc" {...register("optin_tlc")}/>

                            <label htmlFor="optin_tlc">
                                Oui, je souhaite être contacté par TLC Marketing à des fins d'enquêtes de satisfaction, d'information sur nos offres promotionnelles et d'études de marché par e-mail.<sup>(2)</sup>
                            </label>
                        </div>
                        */}
                    </div>

                    <div className={"mandatory"}>
                        Tous les champs marqués d’un astérisque (*) sont obligatoires. Toute demande incomplète ne pourra être validée et traitée.<br/><br/>
                        Les informations personnelles communiquées sur ce formulaire sont destinées à l’usage exclusif de Candia dans l’unique finalité de ce jeu. Conformément à la loi Informatique et Libertés du 6 Janvier 1978, chaque internaute ayant rempli ce formulaire dispose d’un droit d’accès, de rectification ou de radiation des informations personnelles le concernant qu’il peut exercer en s’adressant à contact du DPO Groupe. Pour en savoir plus, vous pouvez consulter la <a href={"/file/Politique%20de%20confidentialité.pdf"} className={"underline"} target="_blank">Politique de Confidentialité</a>.
                    </div>

                    <div id={"rgpd_text_wrapper"}>

                        <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                            <div className={"warning_message"}>Ce champ n’est pas obligatoire.</div>
                            <input type="checkbox" name="optin_tlc" id="optin_tlc" {...register("optin_tlc")}/>

                            <label htmlFor="optin_tlc">
                                Oui, je souhaite être contacté(e) par TLC Worldwide à des fins d’enquêtes de satisfaction, d’information sur nos offres promotionnelles et d’études de marché par e-mail.
                            </label>
                        </div>

                        <p>TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la confidentialité des données, conformément aux dispositions légales précitées. Vos informations seront conservées jusqu’au 30/06/2027, au plus tard conformément à la réglementation si vous avez coché la case opt-in du formulaire de participation. Autrement, vos données seront supprimées à partir du 30/09/2024. Vous bénéficiez d’un droit d’accès, de rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant les données personnelles vous concernant. Afin d’exercer ces droits, nous vous remercions de bien vouloir adresser votre demande auprès de TLC Marketing France par e-mail à <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"} className={"underline"}>francedata@tlcrewards.com</a> ou par courrier à l’adresse suivante : TLC Marketing France – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.</p>
                    </div>

                    {/* recaptcha */}
                    <div className="text-center" id={"recaptcha_wrapper"}>
                        <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b" ref={recaptchaRef}/>
                        <div className="error text-center" id="recaptcha_error">&nbsp;</div>
                    </div>

                    {/* server error */}
                    <div className="text-center">
                        <div className={"error text-center mb-3"} id={"global_error"}>
                            {/*error*/}
                        </div>
                        <input type={"submit"} className={"cta red big"} id={"submit_cta"} ref={submitRef} value={"Je participe"}/>
                    </div>

                </Form>
            </div>
        </div>
    );
};

const JeParticipeMerci = () => {

    return (
        <div className={"merci_wrapper"}>
            <PageTitle title={"merci !"}/>

            <div id={"bravoStrate1"} className={"strate"}>

                <h4>Votre participation <br className={"d-lg-block d-none"}/>a <br className={"d-lg-none"}/>bien été prise en compte.</h4>

                <p>
                    Si elle est valide, vous recevrez sous 2 semaines maximum un <br/>
                    e-mail avec votre code cadeau Académie du Goût et toutes les <br/>
                    instructions pour en bénéficier. Vous serez également <br/>
                    automatiquement inscrit au tirage au sort pour tenter de <br className={"d-lg-none"}/>
                    gagner <br className={"d-lg-block d-none"}/>un repas de chef à domicile.
                </p>

                <p>Dans le cas contraire, si elle n’est pas valide, vous recevrez un<br/> e-mail vous expliquant la raison.</p>

                <p><span className={"fw-bold big"}>En attendant, venez défendre votre team <br className={"d-lg-none"}/>préférée en <br className={"d-lg-block d-none"}/>récoltant le plus de points <br className={"d-lg-none"}/>à notre mini jeu.</span></p>

                <div className={"text-center"}>
                    <Link to={"/jeu"} className={"cta red small"}>Je joue</Link>
                </div>

                <div className={"visual_product"}/>

            </div>

            <TeamBloc/>
            <Contact/>
        </div>
    );
};

const JeParticipe = () => {

    const [showMerci, setShowMerci] = useState(false);

    return (
        <>
            {!showMerci && <JeParticipeForm setShowMerci={setShowMerci}/> }
            {showMerci && <JeParticipeMerci/>}
        </>
    );
};

export default JeParticipe;
