import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

const Footer = () => {

  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div className={"footer_mention_wrapper"}>
          <p>
            *Offre nominative, cumulable, limitée à 1 participation par foyer (même nom et adresse postale) et par adresse e-mail, valable pour l’achat simultané de deux produits éligibles (Lait Frais Marguerite, <br className={"d-none d-lg-block"}/>
            GrandLait Frais et Beurre Nature de Breton) du 19/01/2024 au 31/03/2024 inclus (jusqu’à épuisement des stocks). Preuve d’achat à conserver. L’offre donne droit à un abonnement <br className={"d-none d-lg-block"}/>
            de 3 mois de cours de cuisine en ligne offert valable sur <a href={"https://www.academiedugout.fr"} target={"_blank"} className={"underline"}>www.academiedugout.fr</a> et une chance de gagner un repas de chef à domicile. Voir <a href="/file/Règlement.pdf" className={"yellow underline fw-bold"} target={"_blank"}>Règlement du Jeu complet ici</a>. Visuels non contractuels.
          </p>
          <p className={"fs-italic"}>Team = équipe.</p>
        </div>

        <div className={"footer_menu_wrapper"}>

          <nav>
            <Link to="/#contactStrate1" smooth>Contact</Link>
            <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du jeu</a>
            <a href={"/file/Mentions%20Légales.pdf"} target={"_blank"}>Mentions Légales</a>
            <a href="/file/Politique%20de%20confidentialité.pdf" target={"_blank"}>Politique de Confidentialité</a>
            <a href="/file/Politique%20de%20cookies.pdf" target={"_blank"}>Politique de Cookies</a>
            <Link to="/informations-legales">Informations Légales</Link>
          </nav>
        </div>

        <div className={"footer_logo_wrapper"}>
          <div className={"footer_logo"}>
            {/*<a id={"footer_logo_partenaire"} href={"https://www.academiedugout.fr"} target={"_blank"}/>*/}
            <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
