import React from 'react';
import Contact from "./contact";
import PageTitle from "./_title";

const Attente = () => {

  return(
      <>
          <PageTitle title={"Bonjour"}/>

          <div id="strate1" className={"strate first"}>

              <h4>Nous vous remercions<br/>pour votre visite.</h4>
              <p>
                  Encore un peu de patience les gourmands...<br/>
                  L’opération "Candia Chandeleur" débutera<br className={"d-lg-none"}/> <span>le 19 janvier prochain</span> !
              </p>
              <p>À très vite.</p>

          </div>

          <Contact/>
      </>
  );
}

export default Attente;
